import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a28b078"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = { class: "ion-text-right read-more" }
const _hoisted_4 = {
  key: 0,
  class: "ion-text-left post-date"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    class: "ion-text-center card-box",
    "router-link": {
    name: 'PostDetailPage',
    params: { id: $props.post.id, ...$props.extraRouterParams }
    },
    button: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        src: $props.post.imageLink,
        class: "post-img"
      }, null, 8, _hoisted_1),
      _createVNode(_component_ion_card_header, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createVNode(_component_ion_card_title, {
                color: "dark",
                class: "ion-text-left section-title"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($props.post.title), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createTextVNode(_toDisplayString($setup.t('readMore')) + " ", 1),
              _createVNode(_component_ion_icon, {
                class: "icon-md icon",
                icon: $setup.chevronForwardOutline
              }, null, 8, ["icon"])
            ])
          ]),
          (!$props.hideDate)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($setup.formatDate($props.post.createdAt, 'YYYY-MM-DD')), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_card_subtitle, { class: "ion-text-left post-content" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.post.content), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["router-link"]))
}