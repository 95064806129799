

import { computed, ref } from 'vue';

// swiper
import 'swiper/swiper.min.css';
import '@ionic/vue/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';

// components
import { IonicSlides, IonToolbar, IonSegment, IonSegmentButton, IonLabel,
        IonRow, IonCol, IonChip, } from '@ionic/vue';
import ProductCard from '@/components/product/ProductCard.vue';
import PostCard from "@/components/PostCard.vue";

// composables
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  props: [
    "allCategories",
    "allPosts",
    "allProducts",
    "userLoggedIn",

    // for merchant products only
    "hideMerchantName",
    "showActionButtons",
  ],
  components: { Swiper, SwiperSlide, IonToolbar, IonSegment, IonSegmentButton,
                IonLabel, IonRow, IonCol, IonChip,
                ProductCard, PostCard, },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const { getLocalizedStr, syncFilterBtnPosition, } = utils();

    // 1. declare state variables (ref to make them reactive)
    const categoryFilter = ref("all");
    const categorySegmentBtns = ref([]);
    
    const relatedMerchants = computed(() => store.getters.merchantsWithProductCategory(categoryFilter.value));
    const selectedMerchant = ref("all");

    const slides = ref();
    const setSwiperInstance = (swiper: any) => slides.value = swiper;
    const syncCategoryFilter = () => syncFilterBtnPosition(categorySegmentBtns.value, categoryFilter.value);

    const onCategoryChanged = (newId: any, allCategories: any) => {
      const targetIdx = newId == 'all' ? 0 : (allCategories.findIndex(c => c.id == newId)+1);
      slides.value.slideTo(targetIdx);
      selectedMerchant.value = 'all'; // reset selected merchant
    }
    const onSlideChange = (e: any, allCategories: any) => {
      if (e.activeIndex == 0) categoryFilter.value = 'all';
      else categoryFilter.value = (allCategories[e.activeIndex-1] || {}).id;
      syncCategoryFilter();
    }

    const getFilteredPosts = (posts: any, categoryId: any ) => {
      return posts.filter((post: any) => post.categories.includes(categoryId));
    }
    const getFilteredProducts = (products: any, categoryId: any ) => {
      return products.filter((p: any) => p.categoryId == categoryId);
    }

    return {
      // variables
      categoryFilter, categorySegmentBtns,
      selectedMerchant, relatedMerchants,

      // methods
      t, getLocalizedStr,
      getFilteredPosts, getFilteredProducts,
      syncCategoryFilter, onCategoryChanged,
      setSwiperInstance, onSlideChange,

      // swiper modules
      modules: [IonicSlides],
    }
  }
}
