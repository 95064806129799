
// Vue reactivity
import { computed, ref, } from "vue";

// icons
import { } from "ionicons/icons";

// components
import { IonPage, IonContent, IonGrid, IonSpinner, IonRow, IonCol,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonButton, loadingController, } from "@ionic/vue";
import SwiperCategoryData from '@/components/slides/SwiperCategoryData.vue';
import PageHeader from "@/components/PageHeader.vue";

// composables
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';

// capacitor
import { Share } from '@capacitor/share';

export default {
  name: "MerchantDetailPage",
  components: {
    IonPage, IonContent, IonGrid, IonSpinner, IonRow, IonCol,
    IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonButton,
    PageHeader, SwiperCategoryData,
  },
  setup() {
    // 1. declare state variables (ref to make them reactive) / methods
    const store = useStore();
    const route = useRoute();
    const parentPath = route.params.parentPath; // may be from liked items page
    const currId = route.params.id;

    const userLoggedIn = computed(() => store.state.loggedIn);
    const loading = computed(() => store.state.loadingAppPublicData);
    const merchant = computed(() => store.getters.getMerchantById(currId));
    const merchantProducts = computed(() => store.getters.getProductsByMerchantId(currId));

    // 2. methods or filters
    const { t } = useI18n();
    const { getLocalizedStr, sleep, } = utils();

    const updateLikedMerchant = async (merchant: any, action = 'add') => {
      const loading = await loadingController.create({});
      await loading.present();
      store.dispatch('updateLikedMerchant', { merchantId: merchant.id, action });
      await sleep(1.5); // 1.5 seconds
      loading.dismiss();
      merchant.likedByUser = (action == 'add');
    }

    const shareMerchant = async (merchant: any) => {
      const sharingMsg = `${merchant.name} | ${window.location.href}`;
      try {
        await Share.share({
          title: sharingMsg,
          url: window.location.href,
          dialogTitle: t('MerchantPage.share'),
        });
      } catch (e) {
        const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(sharingMsg)}`
        //const url = `http://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`
        window.open(url, '', 'width=600,height=400');
      }
    };

    // 3. return variables & methods to be used in template HTML
    return {
      // icons

      // variables
      parentPath, loading, merchant, merchantProducts, userLoggedIn,
      shareMerchant,

      // methods
      t, getLocalizedStr, updateLikedMerchant,
    };
  },
};
