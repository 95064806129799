import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ef28b89"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 2 }
const _hoisted_2 = {
  key: 0,
  class: "horizontal-scroll merchant-filter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_post_card = _resolveComponent("post-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_product_card = _resolveComponent("product-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$props.hideMerchantName || $props.allCategories.length >= 1)
      ? (_openBlock(), _createBlock(_component_ion_toolbar, {
          key: 0,
          class: "sticky"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_segment, {
              modelValue: $setup.categoryFilter,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.categoryFilter) = $event)),
              onIonChange: _cache[1] || (_cache[1] = ($event: any) => ($setup.onCategoryChanged($event.target.value, $props.allCategories))),
              scrollable: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_segment_button, { value: "all" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.t('all')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.allCategories, (category, i) => {
                  return (_openBlock(), _createBlock(_component_ion_segment_button, {
                    ref_for: true,
                    ref: el => { $setup.categorySegmentBtns[i] = el },
                    key: category.id,
                    value: category.id
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.getLocalizedStr(category, 'title', 'titleEn')), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ($props.allPosts)
      ? (_openBlock(), _createBlock(_component_swiper, {
          key: 1,
          modules: $setup.modules,
          onSwiper: $setup.setSwiperInstance,
          onSlideChange: _cache[2] || (_cache[2] = ($event: any) => ($setup.onSlideChange($event, $props.allCategories))),
          "slides-per-view": 1
        }, {
          default: _withCtx(() => [
            _createVNode(_component_swiper_slide, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.allPosts, (post) => {
                      return _withDirectives((_openBlock(), _createBlock(_component_ion_col, {
                        "size-xs": "12",
                        "size-md": "6",
                        key: post.id
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(), _createBlock(_component_post_card, {
                            key: post.id,
                            post: post
                          }, null, 8, ["post"]))
                        ]),
                        _: 2
                      }, 1024)), [
                        [_vShow, $setup.categoryFilter == 'all' || post.categories.includes($setup.categoryFilter)]
                      ])
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.allCategories, (category) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, {
                key: category.id
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getFilteredPosts($props.allPosts, category.id), (post) => {
                        return (_openBlock(), _createBlock(_component_ion_col, {
                          "size-xs": "12",
                          "size-md": "6",
                          key: post.id
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(), _createBlock(_component_post_card, {
                              key: post.id,
                              post: post
                            }, null, 8, ["post"]))
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["modules", "onSwiper"]))
      : ($props.allProducts)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (!$props.hideMerchantName)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_ion_chip, {
                    onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.selectedMerchant = 'all')),
                    outline: $setup.selectedMerchant != 'all'
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('all')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["outline"]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.relatedMerchants, (merchant) => {
                    return (_openBlock(), _createBlock(_component_ion_chip, {
                      key: merchant,
                      onClick: ($event: any) => ($setup.selectedMerchant = merchant.id),
                      outline: $setup.selectedMerchant != merchant.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(merchant.name) + " (" + _toDisplayString(merchant.numOfProducts) + ")", 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onClick", "outline"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_swiper, {
              modules: $setup.modules,
              onSwiper: $setup.setSwiperInstance,
              onSlideChange: _cache[4] || (_cache[4] = ($event: any) => ($setup.onSlideChange($event, $props.allCategories))),
              "slides-per-view": 1
            }, {
              default: _withCtx(() => [
                _createVNode(_component_swiper_slide, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_row, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.allProducts, (product) => {
                          return _withDirectives((_openBlock(), _createBlock(_component_ion_col, {
                            "size-xs": "6",
                            "size-md": "4",
                            "size-xl": "3",
                            key: product.id
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_product_card, {
                                product: product,
                                hideMerchantName: $props.hideMerchantName,
                                userLoggedIn: $props.userLoggedIn,
                                showActionButtons: $props.showActionButtons
                              }, null, 8, ["product", "hideMerchantName", "userLoggedIn", "showActionButtons"])
                            ]),
                            _: 2
                          }, 1024)), [
                            [_vShow, $setup.selectedMerchant == 'all' || product.merchantId == $setup.selectedMerchant]
                          ])
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.allCategories, (category) => {
                  return (_openBlock(), _createBlock(_component_swiper_slide, {
                    key: category.id
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getFilteredProducts($props.allProducts, category.id), (product) => {
                            return _withDirectives((_openBlock(), _createBlock(_component_ion_col, {
                              "size-xs": "6",
                              "size-md": "4",
                              "size-xl": "3",
                              key: product.id
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_product_card, {
                                  product: product,
                                  hideMerchantName: $props.hideMerchantName,
                                  userLoggedIn: $props.userLoggedIn,
                                  showActionButtons: $props.showActionButtons
                                }, null, 8, ["product", "hideMerchantName", "userLoggedIn", "showActionButtons"])
                              ]),
                              _: 2
                            }, 1024)), [
                              [_vShow, $setup.selectedMerchant == 'all' || product.merchantId == $setup.selectedMerchant]
                            ])
                          }), 128))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["modules", "onSwiper"])
          ]))
        : _createCommentVNode("", true)
  ], 64))
}